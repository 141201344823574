var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.util.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var sY,Aoa,Boa,Coa,Doa,zY,Eoa,Foa,AY,Goa,DY,Hoa,Ioa,Joa,IY,JY,Koa,Loa,SY,Moa,Noa,Ooa,XY,Poa;sY=function(){};$CLJS.tY=function(a){return null!=a?$CLJS.yc===a.ni?!0:a.Wc?!1:$CLJS.Xa(sY,a):$CLJS.Xa(sY,a)};Aoa=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.uY=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Boa=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Coa=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.vY=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.wY=new $CLJS.M(null,"database-id","database-id",1883826326);Doa=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.xY=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);$CLJS.yY=new $CLJS.M(null,"search","search",1564939822);zY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Eoa=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Foa=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
AY=new $CLJS.M("metabase.lib.schema.metadata","column.has-field-values","metabase.lib.schema.metadata/column.has-field-values",-1383672167);Goa=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.BY=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.CY=new $CLJS.M(null,"dataset","dataset",1159262238);DY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);
Hoa=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.EY=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.FY=new $CLJS.M(null,"definition","definition",-1198729982);Ioa=new $CLJS.M(null,"state","state",-1988618099);$CLJS.GY=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Joa=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.HY=new $CLJS.M("source","joins","source/joins",1225821486);
IY=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);JY=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.KY=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.LY=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);$CLJS.MY=new $CLJS.M(null,"selected?","selected?",-742502788);
Koa=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Loa=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.NY=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.OY=new $CLJS.M("source","card","source/card",-139977973);$CLJS.PY=new $CLJS.M(null,"active","active",1895962068);$CLJS.QY=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.RY=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);SY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Moa=new $CLJS.M(null,"details","details",1956795411);$CLJS.TY=new $CLJS.M("source","fields","source/fields",-649667981);$CLJS.UY=new $CLJS.M(null,"has-field-values","has-field-values",626648377);
Noa=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.VY=new $CLJS.M("source","native","source/native",-1444604147);Ooa=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);$CLJS.WY=new $CLJS.M(null,"auto-list","auto-list",314197731);XY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);
$CLJS.YY=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Poa=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);$CLJS.ZY=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.$Y=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.Y(IY,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ar,$CLJS.OY,$CLJS.VY,$CLJS.vY,$CLJS.xY,$CLJS.TY,$CLJS.hK,$CLJS.ZJ,$CLJS.HY,$CLJS.EY,$CLJS.GY],null));$CLJS.Y(AY,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ar],null),$CLJS.Cz($CLJS.Dd,new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.WY,null,$CLJS.yY,null,$CLJS.fj,null,$CLJS.cz,null],null),null))));
$CLJS.Y(SY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.gK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,$CLJS.RJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SJ,$CLJS.eK],null)],null));
$CLJS.Y(zY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.aK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,$CLJS.RJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.ur],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.ur],null)],null)],null));
$CLJS.Y($CLJS.BY,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.IJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.yj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.xD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ji,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.eK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.cr,$CLJS.yj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pi,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.xD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WJ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.eK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.eK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZY,
new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.oD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.oD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KJ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.TJ],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.oD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,2,[$CLJS.ti,1,$CLJS.Ej,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.UY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VJ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bK,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zY],null)],null)],
null)],null));$CLJS.Y(XY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NY,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[Hoa,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[Goa,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.xD],null)],null)],null)],null)],null)],null));
$CLJS.Y(DY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PY,$CLJS.tr],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ioa,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NY,$CLJS.oD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HJ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,
$CLJS.oD],null)],null)],null));
$CLJS.Y(Noa,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.fK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,$CLJS.TJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wY,$CLJS.UJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.Dj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RY,
new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.Dj],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XJ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.NJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Coa,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,DY],null)],null)],null)],null));
$CLJS.Y(Koa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.MJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,$CLJS.LJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XJ,$CLJS.NJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.Dj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.oD],null)],null)],null));
$CLJS.Y(Loa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.OJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,$CLJS.JJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XJ,$CLJS.NJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.Dj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.oD],null)],null)],null));
$CLJS.Y(Ooa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.QJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,$CLJS.NJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.oD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.oD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.h(null,
1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.oD],null)],null)],null));
$CLJS.Y(Boa,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.cK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,$CLJS.UJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[Foa,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.Dj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Moa,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.Dj],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.$Y,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.Hi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dz,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.Hi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Joa,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YJ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.Dj],null)],null)],null));
$CLJS.Y(JY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"Valid MetadataProvider"],null),new $CLJS.Cc(function(){return $CLJS.tY},Doa,$CLJS.zg([$CLJS.Gi,$CLJS.U,$CLJS.Jj,$CLJS.fi,$CLJS.Ci,$CLJS.Vi,$CLJS.Uj,$CLJS.ei,$CLJS.Fj,$CLJS.Ij],[Aoa,Poa,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ep],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.tY)?$CLJS.tY.H:null]))],null));
$CLJS.Y(Eoa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JY],null)],null)],null)],null));